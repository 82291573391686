/* eslint-disable no-undef */
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { PageContext } from '../../context/context';
import { capitalize, validateEmail } from '../../utils/helperFunctions';
import { constants } from '../../utils/constants';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Button, darken, FormControl, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DeviceFlowTemplate from '../../templates/DeviceFlowTemplate';
import IconButton from '@mui/material/IconButton';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
// import MobileLogin from './MobileLogin';
import RefreshIcon from '@mui/icons-material/Refresh';

import styles from './LoginItem.module.css';
/**
 * LoginSalesforce provides a user interface for logging into Salesforce, allowing the user to select an account and Salesforce instance.
 * It also supports refreshing the list of available instances and logging out.
 *
 * @component
 * @todo - stop allowing gmail.com to change domains in the return statement
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.domain - The initial domain to be used for login, typically derived from user's email domain.
 * @param {Array} props.instance - Initial list of Salesforce instances that the user can select from.
 * @returns {JSX.Element} A component that manages Salesforce login functionalities.
 *
 * @description
 * This component uses several hooks from React:
 * - useState to manage internal state like the list of Salesforce instances, login alert visibility, and selected values.
 * - useEffect to handle component side effects such as prefilling data, updating on prop changes, and external authentication checks.
 *
 * The component leverages Material-UI components to provide a clean and accessible UI. It allows users to:
 * - Select their Salesforce instance from a dropdown.
 * - Refresh the list of Salesforce instances dynamically.
 * - Log in to or log out from Salesforce depending on the current authentication state.
 * - Display alerts for user actions like invalid domain entry or required selections.
 *
 * It handles sensitive operations like authentication checks and user data changes with appropriate UI feedback,
 * such as disabling inputs when logged in and providing visual alerts for errors or required actions.
*/
const LoginSalesforce = ({ domain, instance, isOutlookIOS, setShowMSLogin, showMSLogin }) => {
  // console.log('from loginSalesforce', domain, instance);
  const label = 'salesforce';
  const {
    googleAuthUser, logError, logType, msAuthUser, processMessage, salesforceLoginUser, salesforceLogoutUser, salesforceUser, setPipeVersion, setSalesforceInstance, themeColors
  } = useContext(PageContext);
  const [allowMobileAuth, setAllowMobileAuth] = useState([]);
  const [displayCode, setDisplayCode] = useState(false);
  const [instanceList, setInstanceList] = useState([]);
  const [internalValue, setInternalValue] = useState({ account: '', salesforce: '' });
  const [loginRedirect, setLoginRedirect] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const isOutlook = constants.EMAIL_CLIENT === 'outlook' ? true : false;

  useEffect(() => {
    if (domain) {
      if (!internalValue.account) {
        setInternalValue({
          ...internalValue,
          account: domain
        });
      };
    };
    if (instance) {
      setInternalValue({
        ...internalValue,
        salesforce: internalValue?.salesforce ? internalValue?.salesforce : instance?.[0]?.label
      });
      setInstanceList(instance ? instance : []);
    };
  }, [domain, instance]);

  useEffect(() => {
    if (isOutlook) {
      if (salesforceUser) {
        setInternalValue({
          ...internalValue,
          account: salesforceUser?.account ? `${salesforceUser?.account}.com` : domain,
          salesforce: salesforceUser?.instance ? salesforceUser?.instance : instance?.[0]?.label
        });
      };
    } else {
      window['chrome'].storage.local.get(["investorflow"]).then((result) => {
        if (result?.investorflow) {
          setInternalValue({
            ...internalValue,
            account: result?.investorflow?.account ? result?.investorflow?.account : domain,
            salesforce: result?.investorflow?.instance ? result?.investorflow?.instance : instance?.[0]?.label
          });
        }
      });
    };
  }, []);

  useEffect(() => {
    if (googleAuthUser || msAuthUser) {
      setShowAlert(false);
    }
  }, [googleAuthUser, msAuthUser]);

  useEffect(() => {
    const selectedOrg = instanceList.filter((item) => (item?.label === internalValue?.salesforce));
    // TODO: uncomment to allow device flow check
    // if (selectedOrg?.[0]?.device_flow) {
    //   setAllowMobileAuth(true);
    // } else {
    //   setAllowMobileAuth(false);
    // };

    if (selectedOrg?.[0]?.login_redirect) {
      setLoginRedirect(true);
    } else {
      setLoginRedirect(false);
    };

  }, [internalValue]);

  // api sent to check for user approval when using device flow
  const deviceFlowAuthByUser = async (data, code) => {
    try {
      const tokenInfo = await axios.post(`${data.url}?grant_type=device&client_id=${data.id}&code=${code.device_code}`);
      // console.log('token info', tokenInfo);
      return tokenInfo;
    } catch (error) {
      if (error?.response?.data?.error !== "authorization_pending") {
        console.log('fetch device flow token error', error);
      };
      return error.response;
    };
  };

  const fetchUserData = async (data) => {
    const account = internalValue?.account ? internalValue?.account : domain;
    const client = account?.split(/\.(?=[^\.]+$)/)?.[0];
    const org = internalValue?.salesforce;
    try {
      const response = await axios.get(`${constants.REACT_SERVER_URL}/api/v3/auth/whoami/${client}/${org}/${data.access_token}/${data.refresh_token}`);
      const userInfo = {
        message: JSON.stringify(response.data),
        origin: "self",
        type: "dialogMessageReceived"
      }
      processMessage(userInfo);
    } catch (error) {
      console.log('error fetching user data', error);
    };
  };

  const handleLogin = () => {
    if (internalValue.account) {
      if (!internalValue.salesforce) {
        setShowAlert(
          <Alert variant="outlined" severity="warning" sx={{ marginTop: "1.5rem" }}>
            be sure to select an org
          </Alert>
        );
        return console.log('no org selected');
      };
      if (validateEmail(internalValue?.account)) {
        setSalesforceInstance(internalValue);
        salesforceUser ? handleSalesforceLogOut() : salesforceLoginUser(internalValue?.account ? internalValue?.account : domain, internalValue.salesforce, loginRedirect || isOutlookIOS);
      } else {
        setShowAlert(
          <Alert variant="outlined" severity="warning" sx={{ marginTop: "1.5rem" }}>
            be sure to enter a valid domain
          </Alert>
        );
      };
    } else {
      const message = isOutlook ? `Be sure you are logged into Outlook first to use Addin` : 'Be sure you are logged into Google first to use Addin';
      setShowAlert(<Alert variant="outlined" severity="warning" sx={{ marginTop: "1.5rem" }}>{message}</Alert>);
    };
  };

  const handleSalesforceLogOut = () => {
    setPipeVersion(null);
    salesforceLogoutUser();
    setInternalValue({
      account: domain ? domain : '',
      salesforce: ''
    });
  };

  const handleOnChange = (e) => {
    setInternalValue({
      ...internalValue,
      [e.target.name]: e.target.value
    });
  };

  const handleRefresh = async () => {
    if (validateEmail(internalValue?.account)) {
      try {
        const response = await axios.get(`${constants.REACT_SERVER_URL}/api/v3/auth/loginlist/${internalValue.account}`);
        if (constants.EMAIL_CLIENT === 'outlook') {
          if (logType === 'meeting' && response.data?.[0]?.graphEnabled && !isOutlookIOS) {
            setShowMSLogin(true);
          } else {
            setShowMSLogin(false);
          };
        };
        setShowAlert(false);
        setInstanceList(response.data);
        setInternalValue({
          ...internalValue,
          salesforce: response.data[0]?.label
        });
      } catch (error) {
        // console.warn('error fetching login list', error);
        logError({
          error: error,
          functionName: 'handleRefresh',
          additionalNotes: 'not able to refresh list, check domain and try again',
          displayedToUser: true,
          level: 'warn'
        });
        setShowAlert(
          <Alert variant="outlined" severity="warning" sx={{ marginTop: "1.5rem" }}>
            not able to refresh list, check domain and try again
          </Alert>
        );
      };
    } else {
      setShowAlert(
        <Alert variant="outlined" severity="warning" sx={{ marginTop: "1.5rem" }}>
          be sure to have a valid domain
        </Alert>
      );
    }
  };

  const LoginButton = () => {
    const disabled = (constants.EMAIL_CLIENT !== 'outlook' && !googleAuthUser) || (constants.EMAIL_CLIENT === 'outlook' && !msAuthUser && showMSLogin);
    return (
      <Button variant="contained" size={'medium'} sx={{
        backgroundColor: themeColors.primary, '&:hover': {
          backgroundColor: darken(themeColors.primary, 0.1)
        }
      }}
        disabled={disabled}
        startIcon={salesforceUser ? <LogoutIcon /> : <LoginIcon />}
        onClick={() => handleLogin()}
      >
        {label} {salesforceUser ? 'Logout' : 'Login'}
      </Button>
    );
  };

  // TODO: determine which method of button we want when using device flow
  const MobileLogin = () => {
    return (
      <Button
        disabled={displayCode ? true : false}
        onClick={useDeviceFlow}
        size={'medium'}
        sx={{color:themeColors.primary, borderColor:themeColors.primary, '&:hover': { borderColor:themeColors.primary }}}
        variant={"outlined"}>
        Use Device Flow
      </Button>
      // <Button
      //   disabled={displayCode ? true : false}
      //   onClick={useDeviceFlow}
      //   size={'medium'}
      //   startIcon={salesforceUser ? <LogoutIcon /> : <LoginIcon />}
      //   sx={{ backgroundColor: themeColors.primary, '&:hover': { backgroundColor: darken(themeColors.primary, 0.1) }}}
      //   variant={"contained"}
      // >
      //   {label} {salesforceUser ? 'Logout' : 'Login'}
      // </Button>
    );
  };

  // change back to login options screen from device flow screen
  const onCancel = () => {
    setDisplayCode(false);
  };

  // poll salesforce api at interval until success or rety count limit for device flow auth by user
  const pollApi = async (data, code) => {
    // console.log(code.interval * 1000, 'second intervals between pings');
    let apiCall = async () => await deviceFlowAuthByUser(data, code);
    let retryNum = 0;

    const interval = async () => {
      let intervalCall = await apiCall();
      if (intervalCall?.status === 200) {
        // console.log('success - ending the ping', intervalCall);
        setDisplayCode(false);
        fetchUserData(intervalCall?.data);
        return;
      };

      if (intervalCall?.status !== 200 && intervalCall?.status !== 400) {
        console.log('more error handling needed', intervalCall);
      };

      retryNum++;
      // stop after 60 tries - 5 minutes
      if (retryNum >= 60) {
        setDisplayCode(false);
        return;
      };

      setTimeout(interval, (code.interval * 1000));
    };

    interval();
  };

  // begin device flow auth process by diplaying user code and url, then begin polling for user approval
  const useDeviceFlow = async () => {
    const account = internalValue?.account ? internalValue?.account : domain;
    const org = internalValue?.salesforce;
    try {
      const response = await axios.get(`${constants.REACT_SERVER_URL}/api/v3/auth/device/${account}/${org}`);
      const code = await axios.post(`${response.data.url}?response_type=device_code&client_id=${response.data.id}`);
      setDisplayCode(code.data);
      pollApi(response.data, code.data);
    } catch (error) {
      console.log('snaps add error handling', error);
    };
  };


  return (
    // displayCode
    // ? <DeviceFlowTemplate code={displayCode.user_code} onCancel={onCancel} salesforceLink={displayCode.verification_uri} />
    // : <section className={styles.loginWrapper}>
    <section className={styles.loginWrapper}>
      <Accordion defaultExpanded disableGutters
        sx={{
          border: `1px solid ${themeColors.tertiary || "rgba(0, 0, 0, 0.2)"}`,
          borderRadius: "0!important",
          boxShadow: "none!important",
        }}>
        <AccordionSummary expandIcon={<ArrowDropUpIcon sx={{ color: themeColors.primary }} />} >
          <Typography
            component="h2"
            sx={{
              fontWeight: "bold",
              fontSize: "0.8125rem",
              color: "#37474f",
              lineHeight: "1rem",
            }}>
            {capitalize(label)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            borderTop: "1px solid rgba(0, 0, 0, 0.2)"
          }}>
          <div style={{ fontSize: "0.725rem" }}>
            <Stack spacing={2}>

              {(domain === 'investorflow.com' || domain === 'gmail.com' || domain === 'cloudtheoryinc.com')
                ? <TextField
                  // defaultValue={domain}
                  disabled={salesforceUser ? true : false}
                  label={'Domain'}
                  name={'account'}
                  onBlur={() => handleRefresh()}
                  onChange={(e) => handleOnChange(e)}
                  required
                  size={'small'}
                  value={internalValue.account}
                  variant={'standard'}
                />
                : <Typography>{domain}</Typography>
              }
              {(googleAuthUser || constants.EMAIL_CLIENT === 'outlook') && instanceList && instanceList.length > 0 &&
                <FormControl className={styles.selectWrapper} fullWidth size="small" variant={'standard'}>
                  <Select
                    className={styles.selectItem}
                    disabled={salesforceUser ? true : false}
                    labelId={"salesforce-label"}
                    id={'salesforce'}
                    name={'salesforce'}
                    value={internalValue?.salesforce}
                    label={'salesforce'}
                    onChange={(e) => handleOnChange(e)}
                  >
                    {instanceList && instanceList.map((item, key) => (
                      <MenuItem key={`${key}-instance`} value={item.label}>{item.label}</MenuItem>
                    ))};
                  </Select>
                  <IconButton aria-label="refresh" disabled={salesforceUser ? true : false} title={'click to refresh'} onClick={() => handleRefresh()}>
                    <RefreshIcon />
                  </IconButton>

                </FormControl>
              }

              {showAlert && showAlert}

              <LoginButton />

              {/* { (allowMobileAuth && !salesforceUser && isOutlookIOS) && <MobileLogin /> } */}

            </Stack>
          </div>
        </AccordionDetails>
      </Accordion>
    </section>
  );
};

export default LoginSalesforce;
