/**
 * @fileOverview components to build out buttons to perform various actions
 * @module components/ActionButtons
 * @require @mui/material
*/
import ActionButtons from './ActionButtons';

/**
 * @summary houses buttons
 * @description wrapper to house the action buttons that float at the bottom of the log screen
 * @function
 * @memberof module:components/ActionButtons
 * @param {boolean} isUpdate - bool used to label button either update or save
 * @param {function} onClose - function to handle cancel
 * @param {function} onSave - function to handle saving
 * @returns {component}
*/
const ActionButtonsWrapper = ({ isUpdate, onClose, onSave, testId }) => {
  return (
    <div className="float">
      <ActionButtons
        onCancel={onClose}
        onSave={onSave}
        saveProps={{ label: (isUpdate ? "Update" : "Save") }}
        testId={testId}
      />
    </div>
  );
};

export default ActionButtonsWrapper;
