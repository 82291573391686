import { useContext, useEffect, useState } from 'react';
import { PageContext } from '../../context/context';
import { Checkbox, IconButton, Tooltip } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import ReportIcon from '@mui/icons-material/Report';
import CreateRecord from './CreateRecord';
import InputLookup from '../Inputs/InputLookup';
import ColoredSvg from '../CustomSvg/ColoredSvg';

import styles from './RelatedLists.module.css';

/**
 * RelatedItem component provides a detailed view of an item in a list, with options to edit, create, or link records.
 * It supports displaying titles, labels, descriptions, and interactive elements like checkboxes or action buttons based on configuration.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.checked - Indicates whether the item is selected.
 * @param {Array|string} props.description - The description of the item, can be an array or a string.
 * @param {boolean} props.enableCreateRecord - Enables the functionality to create a new record.
 * @param {boolean} props.enableEdit - Enables the edit functionality.
 * @param {boolean} props.enableSelect - Enables the selection functionality (checkbox).
 * @param {boolean} props.isDetectedEntity - Indicates if the item is detected by an AI or similar tool.
 * @param {boolean} props.isEditClicked - Handler to toggle edit mode on/off.
 * @param {boolean} props.isEditable - Indicates if the item is editable.
 * @param {boolean} props.isLinked - Indicates if the item is linked to another entity.
 * @param {number} props.itemIndex - Index of the item in the list.
 * @param {string} props.label - Label of the item.
 * @param {Object} props.newRecordLayout - Configuration for creating a new record.
 * @param {Function} props.onCreateNewRecord - Callback function when a new record is created.
 * @param {Function} props.onLinkRecord - Callback function when a record is linked.
 * @param {Function} props.onOpenEdit - Callback to handle the visibility of edit UI elements.
 * @param {Function} props.onSelectItem - Callback function when an item is selected.
 * @param {Function} props.onUpdate - Callback function when an item is updated.
 * @param {Object} props.record - The record data associated with the item.
 * @param {boolean} props.requireParentId - Indicates if a parent ID is required for the record operations.
 * @param {Object} props.searchConfig - Configuration for the search functionality.
 * @param {string} props.title - Title of the item.
 * @param {boolean} props.unmatched - Indicates if the item does not match certain criteria.
 * @param {string} props.url - URL for making external links.
 * @returns {JSX.Element} The rendered component showing an individual item with interactive capabilities.
 *
 * @description
 * This component allows for interaction with list items such as emails, contacts, or other related records.
 * It provides functionality for editing, creating, linking, and selecting items, adapting its UI based on the passed props.
 * It makes use of several Material-UI components for its presentation and handles its state and events through React hooks.
 */
const RelatedItem = (props) => {
  // console.log('related Item', props)
  const {
    checked, description, enableCreateRecord, enableEdit, enableSelect, isDetectedEntity, isEditClicked, isEditable,
    isLinked, itemIndex, label, newRecordLayout, onCreateNewRecord, onLinkRecord, onOpenEdit, onSelectItem, onUpdate,
    record, requireParentId, searchConfig, title, unmatched, url
  } = props || {};
  const { themeColors } = useContext(PageContext);

  const [createRecordConfig, setCreateRecordConfig] = useState(null);
  const [openLinkInput, setOpenLinkInput] = useState(false);
  const [value, setValue] = useState(false);

  useEffect(() => {
    if (checked !== value) {
      setValue(!!checked);
    }
  }, [checked]);

  const createNewRecord = (e) => {
    if (typeof onCreateNewRecord === 'function') {
      onCreateNewRecord({ itemIndex });
    } else {
      //Initialize new record layout
      const layout = JSON.parse(JSON.stringify(newRecordLayout));
      layout.inputFields.forEach(inputConfig => {
        if (record[inputConfig.sourceField]) {
          inputConfig.defaultValue = record[inputConfig.sourceField];
        }
      });
      setCreateRecordConfig(layout);
    }
  };

  const Description = ({ des }) => {
    return (
      des.map((item, index) => {
        if (typeof item === "string" && item.indexOf("</") > 0 && item.indexOf("<") >= 0 && item.indexOf(">") > 0) {
          return <div key={`${index}-${item}-email`} dangerouslySetInnerHTML={{ __html: item }}></div>;
        } else if (typeof item === "string" && item.includes('@')) {
          return <span title={item} key={`${index}-${item}-email`}><a href={`mailto:${item}`} target={'_blank'} rel="noreferrer" className={styles.description} style={{ "--themed-primary": themeColors.secondary }}>{item}</a></span>
        } else {
          return <span title={item} key={`${index}-${item}`} className={styles.description}>{item}</span>
        }
      })
    );
  };

  const getWidthFormula = () => {
    let excessSpace = 66; // 24 + 8 + 2 for border size
    if (enableSelect || enableCreateRecord) {
      excessSpace += 14;
    }
    return `calc(100% - ${excessSpace}px)`;
  };

  const openEditRecord = (e) => {
    isEditClicked(true);
    if (requireParentId) {
      const layout = JSON.parse(JSON.stringify(newRecordLayout));
      layout.isCompact = true;
      layout.recordId = record?.Id;
      layout.inputFields.forEach(inputConfig => {
        if (record[inputConfig.targetField]) {
          inputConfig.defaultValue = record[inputConfig.targetField];
        };
      });
      setCreateRecordConfig(layout);
      onOpenEdit(true); // notify parent to hide new window
    } else if (typeof onCreateNewRecord === 'function') {
      onCreateNewRecord({ itemIndex });
    };
  };

  const selectItem = (e) => {
    setValue(!!e.target.checked);
    if (typeof onSelectItem === 'function') {
      onSelectItem(itemIndex);
    }
  };

  return (
    <>
      {createRecordConfig ?
        <div className={"p-around_small"} style={{ marginTop: "1rem" }}>
          <b>{title}</b>
          <CreateRecord {...createRecordConfig}
            onCancel={() => {
              setCreateRecordConfig(null);
              onOpenEdit(false);
            }}
            onSuccessSave={(detail) => {
              onUpdate({ ...detail, itemIndex });
              setCreateRecordConfig(null);
            }} />
        </div>
        :
        <div className="grid" style={{ position: "relative" }}>
          {(enableEdit && isEditable && enableCreateRecord) ?
            <div className={styles.selectableEditBtnContainer}>
              <IconButton title="Edit record" size="small" onClick={openEditRecord}>
                <EditIcon fontSize="small" />
              </IconButton>
            </div>
            :
            (!isEditable && isDetectedEntity) ?
              <div className={styles.selectableEditBtnContainer}>
                <IconButton title="Search and link" size="small" onClick={() => setOpenLinkInput(!openLinkInput)}>
                  <FindReplaceIcon fontSize="small" style={{ color: openLinkInput ? themeColors.primary : null }} />
                </IconButton>
              </div>
              :
              <div style={{ paddingLeft: "2rem" }}></div>
          }
          {openLinkInput ?
            <div className="size_1-1 p-right_small">
              <InputLookup
                autofocus
                label={title}
                config={{
                  attributes: {
                    variant: "standard",
                    newRecordLayout: !!newRecordLayout ? JSON.parse(JSON.stringify(newRecordLayout)) : null,
                    ...searchConfig
                  }
                }}
                onChange={(detail) => {
                  if (detail?.value?.value) {
                    onLinkRecord({ recordId: detail.value.value });
                    setOpenLinkInput(false);
                  }
                }} />
            </div>
            :
            <>
              <div className="grid_vertical"
                style={{
                  paddingRight: "4px",
                  width: getWidthFormula()
                }}>
                <div className="text-ellipsis">
                  {record?.Id
                    ?
                    <span>
                      <a href={`${url}/${record?.Id}`} target={'_blank'} rel="noreferrer"
                        style={{
                          "--themed-primary": themeColors.secondary,
                          color: themeColors.secondary || "#007bc2",
                          textDecoration: "none"
                        }}>
                        <span title={title} className={styles.titleValue}>{title}</span>
                      </a>
                    </span>
                    :
                    <span title={title} className={styles.titleValue}>{title}</span>
                  }
                  {title && label && <span className="p-horizontal_x-small">•</span>}
                  {label && <span>{label}</span>}
                </div>
                {description &&
                  <div className="grid_vertical">
                    <Description des={description} />
                  </div>
                }
              </div>

              {isDetectedEntity &&
                <div className={styles.aiContainer}>
                  <Tooltip placement="left-end"
                    componentsProps={{ tooltip: { className: styles.aiToolTip } }}
                    title="This entity was detected by InvestorFlow AI." >
                    <div className={styles.investorFlowIcon}>
                      <ColoredSvg iconName={"investorFlowMark"} />
                    </div>
                  </Tooltip>
                </div>
              }

              {enableSelect &&
                (isEditable ?
                  <div className={styles.checkboxContainer}>
                    <Checkbox size="medium"
                      style={{
                        color: isLinked ? null : (!!value ? themeColors.primary : "#e0e0e0")
                      }}
                      checked={value}
                      onChange={selectItem}
                      disabled={isLinked} />
                  </div>
                  :
                  enableCreateRecord &&
                  <div className={styles.createButtonContainer}>
                    <IconButton title="Create new record" size="small" onClick={createNewRecord}>
                      <AddBoxIcon fontSize="small" />
                    </IconButton>
                  </div>
                )
              }

              {unmatched &&
                <div className={styles.unmatchedWarning}>
                  <Tooltip placement="left-end"
                    componentsProps={{ tooltip: { className: styles.unmatchedToolTip } }}
                    title="The RecordType of this record does not match any value in the RecordType Map custom setting." >
                    <ReportIcon fontSize="small" />
                  </Tooltip>
                </div>
              }
            </>
          }
        </div>
      }
    </>
  );
};

export default RelatedItem;
