import { useContext } from 'react';
import { PageContext } from '../../context/context';
import { Button, darken, Typography } from '@mui/material';
import msLogo from "../../assets/images/ms-signin.svg";
import LogoutIcon from '@mui/icons-material/Logout';
import LoginItem from './LoginItem';

import styles from './LoginItem.module.css';


const LoginMS = () => {
  const label = 'Microsoft';
  const { msAuthUser, msLogout, msLogin, themeColors } = useContext(PageContext);
  const userName = msAuthUser?.email;

  const Component = () => {
    return (
      <>
        { userName && <Typography variant={"body"} component={"p"}>{userName}</Typography> }
        <div className="grid_center">
          <Button fullWidth variant={msAuthUser ? "contained" : "text"}
            sx={ msAuthUser ? {backgroundColor:themeColors.primary, '&:hover': {
              backgroundColor:darken(themeColors.primary, 0.1)
            }} : {} }
            size={msAuthUser ? "medium" : "small"}
            className={!msAuthUser ? styles.btnWrapper : ''}
            startIcon={msAuthUser ? <LogoutIcon /> : null}
            onClick={() => handleLogin(label)}>
            { !msAuthUser
              ? <img className={styles.btnImg} src={msLogo} alt={'sign in with microsoft'} />
              : `${label} Logout`
            }
          </Button>
        </div>
      </>
    );
  };

  const handleLogin = () => {
    msAuthUser ? msLogout() : msLogin();
  };

  return (
    <LoginItem label={label} Component={Component} />
  );
};

export default LoginMS;
