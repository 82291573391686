import { useContext, useEffect, useState } from 'react';
import { PageContext } from '../../context/context';
import { getTruncatedText } from '../../utils/helperFunctions';
import Editor from 'react-simple-wysiwyg';
import styles from './Inputs.module.css';


/**
 * InputRichText is a component that renders a rich text editor using `react-simple-wysiwyg`. It's designed to provide users
 * with tools to format their text input, which can include styling options like bold, italic, and underlined text.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.config - Configuration for the rich text editor, including any default values and attributes like 'required'.
 * @param {string} props.id - Unique identifier for the rich text input, used for accessibility and managing focus.
 * @param {string} props.label - Label for the input field, describing the field to users.
 * @param {Function} props.onChange - Callback function triggered when the input value changes. It handles data processing and storage.
 * @param {string} props.sectionId - Identifier for the form section that this input belongs to, used for managing state in context.
 * @returns {JSX.Element} A container with a rich text editor.
 *
 * @description
 * This component integrates with a global context (`PageContext`) to manage and store the value of the text input across different uses.
 * It utilizes an internal state to maintain the current value of the input and updates it based on interactions with the editor.
 *
 * Features include:
 * - A rich text editing interface provided by the `react-simple-wysiwyg` editor.
 * - Dynamic state management reflecting the current input, with updates propagated to a central store if needed.
 * - Support for initial default values and handling changes through a controlled component approach.
 * - Real-time updates and optional callback invocation for integrating specific handling logic.
 *
 * The component is particularly useful for forms requiring styled text input or more complex formatting, providing users
 * with a flexible and user-friendly interface for text manipulation.
 */

const InputRichText = (props) => {
  const { config, id, label, onChange, sectionId } = props || {};
  const { attributes, targetField } = config || {};
  const { required } = attributes || {};
  const { handleOnChangeWithDetail, valueStorage } = useContext(PageContext);


  /* START Internal Value */
  const [internalValue, setInternalValue] = useState('');

  useEffect(() => {
    if (valueStorage?.[sectionId]?.[id]?.value) {
      const newValue = getTruncatedText(valueStorage[sectionId][id].value, config);
      if (newValue !== internalValue) {
        setInternalValue(newValue);
      }
    }
  }, [valueStorage, sectionId, id]);

  useEffect(() => {
    if (config?.defaultValue) {
      setInternalValue(getTruncatedText(config.defaultValue));
    }
  }, [config?.defaultValue]);
  /* END Internal Value */

  const handleOnChange = (value) => {
    setInternalValue(value?.target?.value)
    const detail = {
      inputId: id,
      sectionId: sectionId,
      targetField: targetField,
      type: config?.type,
      value: value?.target?.value // value is the updated content
    };
    if (typeof onChange === 'function') {
      onChange(detail);
    } else {
      handleOnChangeWithDetail(detail);
    }
  };

  return (
    <div className={'input-rich-text'}>
      <label className={styles.labelRichText}>
        {label} {required ? '*' : ''}
      </label>
      <Editor id={id} tabIndex={0} containerProps={{ style: { resize: 'vertical' } }} className={styles.customEditor} value={internalValue} onChange={handleOnChange} />
    </div>
  );
};

export default InputRichText;
