import { useContext } from 'react';
import { PageContext } from '../context/context';
import { DmlContext } from '../context/dmlContext';
import AttachmentsPanel2 from '../components/Attachments/AttachmentsPanel2';
import ActionButtonsWrapper from '../components/ActionButtons/ActionButtonsWrapper';
import SectionCmp from "../components/Sections/SectionCmp";
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';

import styles from './templates.module.css';

/**
 * @summary Log Case Template
 * @interface LogCaseTemplate
 * @description Screen for logging a case / investor request
 */
const LogCaseTemplate = () => {
  const { attachmentsConfig, caseConfig, caseId, logScreenBottom, saveResultToast, setScreenType, themeColors } = useContext(PageContext);
  const { saveCase } = useContext(DmlContext);

  return (
    <>
      <div role="heading" aria-level="1" className={styles.logScreenTitle} style={{ backgroundColor: themeColors.primary }}>
        <WorkOutlineIcon sx={{ padding: "2px 8px" }} />
        {caseConfig.buttonLabel}
      </div>

      {caseConfig.sections &&
        caseConfig.sections.map((item, key) => (
          <SectionCmp key={`${item.id}-${key}-case`} config={item} />
        ))
      }

      {attachmentsConfig?.forCase &&
        <div style={{ paddingTop: "0.25rem" }}>
          <AttachmentsPanel2 hideSaveButton={true}
            enableCase={true}
            enableEmail={false}
            enableMeeting={false}
            selectByDefaultCase={!caseId && attachmentsConfig.selectByDefaultCase} />
        </div>
      }

      {saveResultToast && saveResultToast}

      <div className={styles.buttonPadding} ref={logScreenBottom}></div>

      <ActionButtonsWrapper
        isUpdate={!!caseId}
        onSave={saveCase}
        onClose={() => setScreenType("intro")} 
        testId={'ea-submit-case'} />
    </>
  );
};

export default LogCaseTemplate;
