/* eslint-disable no-undef */
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { DmlProvider } from './context/dmlContext';
import { PageProvider } from './context/context';
import { LoadingProvider } from './context/loadingContext';

const title = "InvestorFlow";
let isOfficeInitialized = false;
let _item = {};
const root = ReactDOM.createRoot(document.getElementById('root'));


/**
 * @summary Inject react app into html
 * @namespace Pages/Index
 * @description Wraps the react app in context providers.
 */
const render = (Component, OfficeInfo) => {
  root.render(
    <LoadingProvider>
      <PageProvider isOfficeInitialized={isOfficeInitialized} OfficeInfo={OfficeInfo} officeItem={_item}>
        <DmlProvider>
          <Component title={title} isOfficeInitialized={isOfficeInitialized} OfficeInfo={OfficeInfo} />
        </DmlProvider>
      </PageProvider>
    </LoadingProvider>
  );
};

if (process.env.REACT_APP_PACKAGE === 'outlook') {
  /* Render application after Office initializes */
  if (Office !== undefined) {
    Office.onReady()
    .then(async (info) => {
      // console.log('Office info', info);
      isOfficeInitialized = true;
      _item = Office?.context?.mailbox?.item;
      render(App, info);

      if ((module).hot) {
        (module).hot.accept("./App", function () {
          const NextApp = require("./App").default;
          render(NextApp);
        });
      };
    });
  };
} else {
  render(App);
};
