/**
 * @fileOverview variables used throughout the app
 * @namespace constants
*/
const constants = {
  EMAIL_CLIENT: process.env.REACT_APP_PACKAGE === 'outlook' ? 'outlook' : 'gmail',
  ENTITY_RECOGNITION_CACHE_LIMIT: 100,
  ENTITY_RECOGNITION_CHARACTER_LIMIT: 5000,
  REACT_ATTACHMENT_ENDPOINT: 'https://gmail.googleapis.com/gmail/v1/users',
  REACT_CALENDAR_ENDPOINT: 'https://www.googleapis.com/calendar/v3/calendars',
  REACT_GMAIL_ENDPOINT: 'https://gmail.googleapis.com/gmail/v1/users/me/messages',
  REACT_SERVER_URL: location?.host === 'localhost:3006' ? 'https://localhost:3000' : location?.host === 'outlook-dev.apps.investorflow.com' ? 'https://addin-dev.apps.investorflow.com' : 'https://addin.apps.investorflow.com',
  // REACT_SERVER_URL: 'https://addin.apps.investorflow.com', // prod
  // REACT_SERVER_URL: 'https://addin-dev.apps.investorflow.com', // dev
  // REACT_SERVER_URL:'https://localhost:3000',
  // REACT_SERVER_URL:'http://localhost:3000',
  REACT_SF_API_VERSION: 'v58.0',
  ENTITY_RECOGNITION_STORAGE: 'DetectedEntityStorage',
  MS_GRAPH_CLIENT_ID: process.env.REACT_APP_MS_NAA_CLIENT_ID
};

export { constants };
