import { useContext } from 'react';
import { PageContext } from '../../context/context';
import { Stack, Badge, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

/**
 * @summary copy/paste buttons
 * @description builds out the copy paste buttons
 * @function
 * @memberof module:components/ActionButtons
 * @param {string} sectionId - id for the copy/paste buttons
 * @returns {component}
*/
const SectionHeaderButtons = ({ sectionId }) => {
  const { copyItemCount, copyStorage, linkStorage, sections, setAutoPopStorage, setCopyItemCount, setCopyStorage, setLinkStorage, setSections } = useContext(PageContext);

  const appendSectionData = (sectionId) => {
    if (copyItemCount > 0) {
      const newStorage = {};
      sections.forEach(sectionConfig => {
        if (sectionConfig.id === sectionId) {
          sectionConfig.inputFields.forEach(inputConfig => {
            if (copyStorage[inputConfig.id]) {
              newStorage[inputConfig.id] = copyStorage[inputConfig.id];
            };
          });
        };
      });
      setAutoPopStorage(newStorage);
    };
  };

  const copySectionData = (sectionId) => {
    const tempStorage = {};
    let count = 0;
    sections.forEach(sectionConfig => {
      if (sectionConfig.id === sectionId) {
        sectionConfig.inputFields.forEach(inputConfig => {
          if (inputConfig.targetObject === `CT_PE__Activity_Link__c`) {
            if (linkStorage[inputConfig.targetField]) {
              tempStorage[inputConfig.id] = JSON.parse(JSON.stringify(linkStorage[inputConfig.targetField]));
              count += linkStorage[inputConfig.targetField].length;
            };
          };
        });
      };
    });
    setCopyItemCount(count);
    setCopyStorage(tempStorage);
  };

  const replaceSectionData = (sectionId) => {
    if (copyItemCount > 0) {
      const newSections = JSON.parse(JSON.stringify(sections));
      const newLinkStorage = JSON.parse(JSON.stringify(linkStorage));
      newSections.forEach(sectionConfig => {
        if (sectionConfig.id === sectionId) {
          sectionConfig.inputFields.forEach(inputConfig => {
            if (copyStorage[inputConfig.id]) {
              inputConfig.defaultValue = [...copyStorage[inputConfig.id]];
            } else if (inputConfig.targetObject === `CT_PE__Activity_Link__c`) {
              inputConfig.defaultValue = [];
            };
            delete newLinkStorage[inputConfig.targetField];
          });
        };
      });
      setLinkStorage(newLinkStorage);
      setSections(newSections);
    };
  };


  return (
    <Stack direction="row" spacing={1}>
      <IconButton title="Copy section items"
        sx={{ padding: "0!important", color: "#37474f", }}
        onClick={(e) => { e.stopPropagation(); copySectionData(sectionId); }}>
        <ContentCopyIcon />
      </IconButton>
      <IconButton title="Replace copied items"
        sx={{ padding: "0!important", color: "#37474f", }}
        onClick={(e) => { e.stopPropagation(); replaceSectionData(sectionId); }}>
        <Badge badgeContent={copyItemCount} color="primary"
          slotProps={{ badge: { sx: { border: "2px solid white", padding: "0 4px" } } }}>
          <ContentPasteIcon />
        </Badge>
      </IconButton>
      <IconButton title="Add copied items"
        sx={{ padding: "0!important", color: "#37474f", }}
        onClick={(e) => { e.stopPropagation(); appendSectionData(sectionId); }}>
        <Badge badgeContent={copyItemCount} color="primary"
          slotProps={{ badge: { sx: { border: "2px solid white", padding: "0 4px" } } }}>
          <NoteAddIcon />
        </Badge>
      </IconButton>
    </Stack>
  );
};

export default SectionHeaderButtons;
