import { useContext } from 'react';
import { PageContext } from '../../context/context';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import styles from './ActionButtons.module.css';

/**
 * @summary action buttons
 * @description builds out the custom action buttons
 * @function
 * @memberof module:components/ActionButtons
 * @param {boolean} props.isCompact - bool used to control size of buttons
 * @param {string} props.saveProps.label - label for save button
 * @param {function} props.onClose - function to handle cancel
 * @param {function} props.onSave - function to handle saving
 * @returns {component}
*/
const ActionButtons = (props) => {
  const { isCompact, saveProps, onCancel, onSave, testId } = props || {};
  const saveLabel = saveProps?.label ? saveProps?.label : 'Save';
  const { themeColors } = useContext(PageContext);

  return (
    <>
      { isCompact ?
        <aside className="grid_reverse">
          <Stack direction="row" spacing={1}>
            {(onCancel && typeof onCancel === 'function') && <Button size="small" variant="outlined" onClick={onCancel}>Cancel</Button>}
            <Button size="small" variant="contained" onClick={onSave} data-testid={testId}>{saveLabel}</Button>
          </Stack>
        </aside>
        :
        <aside className={styles.actionButtons}>
          <Stack direction="row" spacing={1}>
              <Button className={styles.cancelButton} sx={{color:themeColors.primary, borderColor:themeColors.primary}} size="medium" variant="outlined" onClick={onCancel}>Cancel</Button>
              <Button className={styles.saveButton} sx={{backgroundColor:themeColors.primary}} size="medium" variant="contained" onClick={onSave} data-testid={testId}>{saveLabel}</Button>
          </Stack>
        </aside>
      }
    </>
  );
};

export default ActionButtons;
